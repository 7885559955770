
body {
    margin: 0;
    padding: 0;

}

.instructionsBackGround {
    position: relative;
    background-color: #000000;
    background-size: cover;
    display: flex;
    width: 100%;
    height: 100vh; 
    font-family: Arial, sans-serif;
    font-size: 14px;
    justify-content: center; 
    align-items: center; 
}

.howToTable {
    td {
        outline: 1px solid #999999;
        padding: 8px;
        text-align: left;
    }
}

.backgroundDemo { 
    position: relative;
    width: 60%;
    height: 450px; 
}

.demoFlex {
    display: flex;
    align-items: center;
}

.iconDemo {
    width: 90%;
}

.effectsDemo {
    width: 50%;
}


hr {
    width: 90%;
}

@media (max-width: 768px) {
    .backgroundDemo {
        width: 80%;
        height: 350px;
    }

    .iconDemo {
        width: 80%;
    }

    .effectsDemo {
        width: 70%;
    }

    .instructions {
        width: 80%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .backgroundDemo {
        width: 100%;
        height: 250px;
    }

    .iconDemo {
        width: 100%;
    }

    .effectsDemo {
        width: 100%;
    }

    .instructions {
        width: 100%;
        height: auto;
        padding: 10px;
    }
}

.instructions {
    border: 2px solid #ffffff;
    width: 40%;
    height: 90%;
    background-color: #313131bb;
    color: white;
    text-shadow: -1px -1px 0 #000,  
               1px -1px 0 #000,
              -1px  1px 0 #000,
               1px  1px 0 #000; 
               overflow-y: scroll;
}

.videoBackground {
    border: none; 
    position: absolute;
    background-color: #000000;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    width: 100%;
    height: 100vh; 
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    justify-content: center; 
    align-items: center; 
    z-index: -2;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    justify-content: center; 
    align-items: center;  
    gap: 20px; /* Space between boxes */
}

.card {
    position: relative;
    border: 2px solid #ffffff;
    border-bottom-left-radius: calc(.05 * var(--iconWidth));
    border-bottom-right-radius: calc(.05 * var(--iconWidth));
    border-top-left-radius: calc(.3 * var(--iconWidth));
    border-top-right-radius: calc(.3 * var(--iconWidth));
    text-align: center;
    background-color: #949e9bac;
}

.chosenCard {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    border: 2px solid #ffffff;
    background-color: #949e9bac;
}

.chosenHpContainer {
    position: relative;
    text-align: center;
}

.chosenHp {
    color: rgb(62, 172, 74);
    font-weight: bold;
    width: 100%;
    height: 100%;
}

.name {
    font-size: calc(.08 * var(--iconWidth)); 
    width: 100%;
    overflow: hidden;       
}

.lastName {
    position: relative;
    font-size: calc(.05 * var(--iconWidth));
    margin-left: 25%;
    margin-right: 25%;
    bottom: 5px;
    overflow: hidden;       
    height: 10%;
}

.initiative-box,
.hp-box {
    display: flex; 
    position: absolute;
    justify-content: center; 
    align-items: center;
    background-color: #f0f0f0;
    border-top: 1px solid #3d3d3d;
    border-right: 1px solid #3d3d3d;
    height: 8%;
    font-size: calc(.07 * var(--iconWidth)); /* Font size is 10% of the parent's width */
    min-width: 12%;
    bottom: 0;
}

.initiative-box {
    right: 0px;
    border-bottom-right-radius: calc(.0412 * var(--iconWidth));
    border-top-left-radius: calc(.04 * var(--iconWidth));
    border-left: 1px solid #3d3d3d;
    border-right: none;
}

.hp-box {
    border-bottom-left-radius: calc(.0412 * var(--iconWidth));
    border-top-right-radius: calc(.04 * var(--iconWidth));
}

.hp {
    margin-right: 3px;
}

.newTabLink {
    margin-left: 10px;
    border: 2px solid #00ffff00;
    border-radius: 10px;
}

.newTabLink:hover {
    border: 2px solid #ff0000;
}

@keyframes shadowPulse {
    0%, 100% {
      box-shadow: 0 0 5px 2px rgba(142, 242, 255, .5);
    }
    50% {
      box-shadow: 0 0 8px 3px rgb(142, 242, 255);
    }
}

@keyframes shadowPulseTurn {
    0%, 100% {
      box-shadow: 0 0 3px 0px rgb(0, 174, 197);
    }
    50% {
      box-shadow: 0 0 10px 3px rgb(0, 122, 138);
    }
}

@keyframes playerViewIconShadowPulse {
    0%, 100% {
        box-shadow: 
            inset 0px -30px 30px -10px rgb(0, 255, 255), 
            0px 0px 5px 5px var(--static-border);
    }
    45%, 55% {
        box-shadow: 
            inset 0px -40px 30px 20px rgb(0, 255, 255), 
            0px 0px 5px 5px var(--static-border);
    }
}

.options-container {
    display: flex;
    padding: 10px; 
    background-color: #0000005e;
    align-items: center
}

.option {
    margin: 5px; 
    height: 20px; 
    width: 20px; 
    cursor: pointer; 
    border-radius: 10px;
}

.option-no-margin {
    margin: 0px
}

.option-no-bot-margin {
    margin-bottom: 0px
}

.option-no-radius {
    border-radius: 0px;
}

.option:hover {
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.5);
}

.tooltiptext img {
    margin: 0 5px 0 5px; 
    height: 20px; 
    width: 20px; 
    border-radius: 10px;
}

.timerControls {
    display: flex;
    flex-direction: row;
    background-color: rgba(140, 140, 114, 0.645);
    border-radius: 5px;
    height: 90%;
    padding-right: 3px;
}

.timer {
    position: relative;
    width: fit-content;
    font-size: 20px;
    margin-top: 3px;
    margin-left: 3px;
}

.refresh-container {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    padding: 10px; 
    background-color: #ffffff2e;
}

.image-container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
    height: 80%;
    margin-top: 5%;
    padding-bottom: 0px;
    border-bottom-left-radius: calc(.15 * var(--iconWidth));
    border-bottom-right-radius: calc(.15 * var(--iconWidth));
    border-top-left-radius: calc(.275 * var(--iconWidth));
    border-top-right-radius: calc(.275 * var(--iconWidth));
}

.image-container .image {
    position: absolute;
    display: block;
    width: 90%;
    height: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.image-container .imageSmoke {
    position: absolute;
    width: 90%;
    height: 100%;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background: linear-gradient(to top, #0000009c, #00000000);
}

.avatarEffectsBar {
    position: absolute;
    bottom: 0%;
}

.option:hover + .tooltiptext,
.effect:hover + .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.overlay-skull {
    background-color: #651d1d;
    opacity: .35; 
}

.overlay-blood {
    opacity: .8; 
}

.loading {
    color: white;
    font-size: xx-large;
}

.hoverPopup {
    position: relative;
    border-radius: 5px;
    border: 1px solid #000000;
    background-color: #e0d9b7;
    box-shadow: 1px 1px 10px #000000;
    width: 30vw;
    padding: 5px 0 5px 5px;
    z-index: 2;
    max-height: 70vh;
    overflow-y: scroll;
    font-family: Bitter, serif;

}

.effectsBarContainer {
    position: fixed;
    border-radius: 5px;
    border: 1px solid #000000;
    background-color: #e0d9b7;
    box-shadow: 1px 1px 10px #000000;
    width: fit-content;
    z-index: 9999;
    padding: 5px;
}

.effectsBarPlayerAvatar {
    position: absolute;
    width: 70px;
    height: 70px; 
    top: -45%;
    right: 43%;
    border: 2px solid #ffffff;

}

.effectsBar {
    display: grid;
    border: 2px solid #ffffff;
    width: 500px;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.9); 
}

.effectPlayerView {
    width: calc(.25 * var(--iconWidth)); 
    height: calc(.25 * var(--iconWidth)); 
    top: 50%;
    margin: 1px;
}

.effect {
    width: 40px; 
    height: 40px; 
    top: 50%;
    margin: 1px;
    border-radius: 15px;
}

.tooltiptext {
    visibility: hidden;
    background-color: rgb(71, 71, 71);
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 55%; 
    left: 50%;
    transform: translateX(-50%); 
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 99;
    font-family: 'Bitter', serif;
    width: fit-content;
}

.roundNum {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: x-large;
    display: inline;
    text-shadow: 
        -1px -1px 0 white,  
        1px -1px 0 white,  
        -1px 1px 0 white,  
        1px 1px 0 white; /* Create the white outline effect */}

.options-container .tooltiptext,
.refresh-container .tooltiptext {
    display: flex;
    bottom: 110%;
}

.effectWrapper {
    position: relative;
    text-align: center;
    border-radius: 100%;
}

.optionWrapper {
    position: relative;
}

.searchListOption {
    bottom: -5px;
}

.optionWrapper .tooltiptext,
.avatarEffectsBar .tooltiptext,
.effectWrapper .tooltiptext {
    bottom: 110%;
    width: 90px;
}

.exhaustionContainer {
    position: absolute;
    left: 0%;
    bottom: 11%;
}

.exhaustionImg {
    position: relative;
    width: 20px; 
    height: 20px; 
    color: white;
    border-radius: 3px;
}

.exhaustionText {
    position: absolute;
    left: 95%;
    bottom: 3px;
    color: rgb(0, 0, 0);
    border-radius: 3px;
    margin: 0%;
    font-size: smaller;
}

.uploadContainer {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.uploadedTitle {
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.grid {
    position: absolute;
    width: 100%;
    height: 45%;
    bottom: 10%;
    display: grid;
    grid-template-columns: repeat(3, 20%);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
}

.circle {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
    border: 1px solid #000000;
}

.searchListIconImage {
    width: 20px;
}

.background {
    display: flex;
    font-family: 'Bitter', serif;
    height: 100vh;
    background-image: url('../pics/dmViewPaperBack.jpg');
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    overflow: hidden;
}

.column {
    flex: 1;
    position: relative;
    width: 24%;
    height: 97vh;
    border: 2px solid #7f7977;
    border-radius: 10px;
    margin: 10px;
    box-sizing: border-box; /* Include border and padding in the element's total width and height */
    color: #822000;
    transition: width 0.3s ease-in-out; /* Smoothly adjust width */
}

.playerViewAdds {
    overflow: hidden;
    position: relative;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    justify-content: center; 
    align-items: center; 
}

.joinGivenName {
    position: absolute;
    right: 15px;
    top: 5px;
    background: white;
}

.whoAreYouContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center
}

.joinMessageTitle {
    position: absolute;
    top: 10%;
    background: white;
}

.joinMessageList {
    position: absolute;
    left: 2px;
    bottom: 2px;
}

.joinMessageListItem {
    background-color: white;
}

.firstLoadMenuContainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.firstLoadMenu {
    position: relative;
    display: flex;
    flex-direction: column;
    top: 20%;
    height: 55%;
    width: 50%;
    background-color: #1f1f1fc2;
    color: #ffffff;
    border-radius: 30px;
}

.homePageGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Three columns */
    grid-template-rows: 1fr 1fr;        /* Two rows */
    grid-template-areas:
        "loginArea cell1 cell2"
        "loginArea cell3 cell4";
    padding: 20px;
    height: 100%; /* Adjust as needed */
}

.gridCell {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-wrap: nowrap;
}

/* Define grid area for each cell */
.largeCell {
    grid-area: loginArea;
    display: flex;
    width: 200px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid grey;
    padding-top: 28%;
}

.gridCell:nth-child(2) { grid-area: cell1; }
.gridCell:nth-child(3) { grid-area: cell2; }
.gridCell:nth-child(4) { grid-area: cell3; }
.gridCell:nth-child(5) { grid-area: cell4; }

/* Styling for the login inputs */
.loginContainer {
    display: flex;
    flex-direction: column;
    width: 80%; /* Adjust to desired width */
}

.homePageButton {
    padding: 8px;
    cursor: pointer;
}

.loginButtons {
    padding: 5px;
    cursor: pointer;
}

.newAccountButton {
    font-size: x-small;
}

.info {
    font-size: small;
    text-align: left;
    text-wrap: wrap;
    border: 2px dotted rgb(123, 0, 0);
    padding: 2px;
    width: 108%;
    background-color: #323232;
}

.submitCreate,
.cancelCreate {
    text-decoration-thickness: 3px;
    cursor: pointer;
}

.cancelCreate {
    text-decoration: underline #dc3700;
}

.submitCreate {
    text-decoration: underline #2c9100;
}

.cancelCreate:hover,
.submitCreate:hover {
   font-size: larger;
}

.createAccountFlex {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    cursor: pointer;
    width: 105%;
}

.loginInput {
    margin: 0;
    width: 100%;
    padding: 8px;
    outline: none
}

.loginMatch {
    margin: 0;
    width: 100%;
    padding: 8px;
}

.loginError {
    border-bottom: 1px solid rgb(107, 0, 0);
    margin: 4px;
}


.helpLink {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #f1f1f1;
    font-size: smaller;
    font-weight: normal;
}

.homepageTopContent {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.userContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    overflow-y: auto;
}

.optionColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center
}

.homePageButtonFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.firstLoadOptionsImports {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-size: small;
}

.firstLoadExtra {
    font-style: italic;
    font-size: small;
}

.searchBar {
    width: 95%;
}

.column.expand {
    width: 45%;
}


.react-tabs {
    height: 100%;
}

.monsterSearch {
    height: 81%;
    overflow-y: scroll; 
    width: 100%;
    border-bottom: 2px solid #822000;

}

.monsterSearchList {
    padding: 0px;
    margin: 0;
}

.listItem:hover .searchListCreatureContainer {
    background-color: rgb(186, 186, 186);
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.5);
}

.monsterSearchItem {
    position: relative;
    width: 95%;
    padding: 2px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: background-color 0.1s ease;
}

.monsterSearchAdd {
    position: relative;
    width: 50px;
    opacity: 0;
    height: 100%;
}

.monsterSearchDetailText {
    margin-right: 2px;
    font-size: 12px;
    font-style: italic;
    color: black
}


.encounterCreaturesNoItemsContainer {
    margin-top: 25%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.encounterCreaturesNoItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3%;
    color: rgb(0, 0, 0);
    border: 3px solid #711d1d;

}

.submitButton {
    padding: 0px;
    border: 0px;
    background-color: #00000000;
    cursor: pointer;
}

.encounterCreatureX:hover,
.listItem:hover .encounterCreatureX {
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.actionTrash {
    opacity: 0;
}

.actionTrash:hover,
.actionListItem:hover .actionTrash {
    opacity: 1;
    transition: opacity .4s ease-in-out;
}

.spellUl {
    margin: 0;
}

.spellTypeRemove {
    display: flex;
    align-items: center
}

.spellTypeTrash,
.spellTrash {
    opacity: 0;
}

.spellLi {
    display: flex;
}

.spellTypeRemove:hover .spellTypeTrash,
.spellTrash:hover,
.spellLi:hover .spellTrash {
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.encounterTitleEditInput {
    width: 90%;
    margin: 0px;
    padding: 0px;
    background-color: #00000000;
    color: #822000;
    color: #bfbba6;
    font-weight: bold;
    font-family: 'Bitter', serif;

}

@keyframes grow {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideUp {
    0% {
      transform: translateY(100%);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

@keyframes slideLeft {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}

.animated-box {
    animation: slideUp 0.5s ease-out forwards;
    /* Initial position for the slide up effect */
    transform: translateY(200%);
}

.animated-column {
    animation: slideLeft 0.4s ease-out forwards;
}
  
@keyframes imageDrop {
    0% {
        
        transform: scale(1.5) rotate(5deg);
        opacity: 0;
    }
    40% {
        transform: scale(1) rotate(-5deg);
    }
    55% {
        transform: scale(1.1);
    }

    60% {
        transform: rotate(3deg);
    }

    60%, 100% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
}

@keyframes imageGrow {
    0% {
        transform: scale(.3);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.growImage {
    animation: imageGrow .2s ease-in forwards;
}

.iconImageDrop {
    animation: imageDrop .8s ease-in forwards;
}

.animated-label {
    display: inline-block; /* Ensure the label scales correctly */
    animation: grow 0.3s ease-in-out forwards;
}

.grow {
    animation: grow .3s ease-in-out forwards;
}

input {
    margin-top: 10px; 
}

.titleLabel {
    color: grey;
    font-weight: bold;
}

.dmLowOptions {
    display: flex;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinningImage {
    animation: spin 1.5s linear infinite; /* Spin for 3 seconds, infinite loops */
}

.statBlockSpinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;

}

.encounterControlsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 96.6%;
    margin-top: 10px;
    box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.5);
    background-color: #e0d8b2;
    border-radius: 10px 10px 0px 0px;
    padding: 10px 0px 0px 10px;
    border-bottom: 2px solid #444444;
}

.searchInputFlex {
    display: flex;
}

.saveDisclaimer {
    color: #ff4000;
    font-style: oblique;
    font-weight: bolder;
}

.encounterControls {
 display: flex;
}

.encounterControlsLeft {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.encounterCountrolsRight {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
}

.encounterControlsContainer .nameInput {
    font-size: larger;
}

.encounterTitle {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    align-items: flex-end;
    font-size: large;
}

.encounterTitleEdit {
    visibility: hidden; 
}

.nameInput:hover + .encounterTitleEdit {
    visibility: visible; 
    cursor: pointer;
}

.titleFontFamily {
    font-family: "Spectral SC", serif;
    margin: 0px;
    color: #822000;
}

.statBlock {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    height: 100%
}

.statBlock p, h1, h5 {
    margin: 3px;
}

.textShadow {
    text-shadow: -.5px .5px 0px #000000;
}


.spellBlockContainer {
    display: flex;
    flex-direction: column;
    padding: 5px 5px 5px 5px;
    height: 100%;
    width: 100%;
    box-sizing: border-box; /* Include border and padding in the element's total width and height */
    background-color: #f8f2d3d7;
    border-radius: 5px;
}

.topSpellInfo {
    display: flex;
    background-color: #e0dab8;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 2px;
    margin-right: 5px;
}

.spellTable {
    margin: 10px 0px 10px 0px;
    padding: 5px;
    box-shadow: inset 0px 0px 2px 2px rgb(99, 99, 99);
    width: 100%;
}

.spellTableDice {
    background-color: #e0dab8;
    white-space: nowrap;
    font-weight: bold;
    width: 15%;
    text-align: center;
    vertical-align: middle;

}

.spellTable td, th {
    border-bottom: 1px solid #b5b5b5;
    border-right: 1px solid #b5b5b5;
} 

.spellTable th {
    background-color: #e0dab8;
} 

.spellStatGrid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    color: #3c3c3c;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    padding: 5px 10px 5px 10px;
    height: 100%;
    width: 100%;
    box-sizing: border-box; /* Include border and padding in the element's total width and height */
    background-color: #f8f2d3d7;
    border-radius: 8px;
}

.imageContainer {
    display: flex;
}

.imageContainer .backgroundThumbnail {
    width: 100%; /* Stretches the image to fill the container width */
    height: 100%;
    object-fit: fill; /* Makes the image fill the size while maintaining aspect ratio */
    aspect-ratio: 1 / 1;
    transition: transform .1s ease-in-out; /* Smooth transformation */
}

.imageX:hover,
.imageContainer:hover .imageX,
.backgroundThumbnail:hover {
    opacity: 1;
    cursor: pointer;
    transform: scale(1.1);
}

.imageX {
    opacity: 0;
    position: relative;
    top: 2px;
    right: 25px;
    z-index: 1;
    width: fit-content;
    height: fit-content;
    padding: 0px;
    border-radius: 4px;
    border: 2px solid red;
}

.statblockOptionsFlex {
    display: flex;
    position: absolute;
    height: 8%;
    width: 95%;
    align-items: center;
    visibility: visible;
}

.statblockOptionsFlexLeft,
.statblockOptionsFlexRight {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.statblockEditInfo {
    cursor: pointer;
    z-index: 1;
    text-wrap: nowrap;
    border: 1px solid #575757;
    border-radius: 100%;
    margin: 2px;
}

.statblockEdit,
.statblockX {
    cursor: pointer;
    z-index: 1;
    margin: 0px 3px 3px 3px;
    text-wrap: nowrap;
    padding: 3px;
    border: 1px solid #575757;
    border-radius: 4px;
}

.statBlock:hover .statblockOptionsFlex {
    visibility: visible;
}

.statblockEdit:hover,
.statblockX:hover {
    background-color: rgb(225, 225, 225);
}

.statBlockTopButtons {
    display: flex;
    justify-content: center;
}

.editSpellBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    gap: 5px;
}

.editBlock {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.editBlockTitle {
    padding-right: 5px;
    font-weight: bold;
    color: #822000;
    text-wrap: nowrap;
}

.globalColor {
    color: #822000
}

.editBlockInput {
    width: fit-content;
    font-family: 'Bitter', serif;
    font-size: 1em;
    background-color: #ffffff6d;
    border: none;
    margin: 0;
    border-bottom: 3px dotted #919191;
    border-radius: 5px;
}

.editBlockBigInput {
    width: 100%;
    font-family: 'Bitter', serif;
    font-size: 1em;
    background-color: #ffffff6d;
    border: none;
    margin: 0px 0px 10px 0px;
    border-bottom: 3px dotted #919191;
    border-radius: 5px;
}

.lineSeperator {
    position: relative;
    border: none; /* Remove default styling */
    border-top: 2px solid #822000; /* Change color and thickness */
    width: 100%; /* Set the width to 50% of the parent container */
    margin: 0px;
}

.editlineSeperator {
    position: relative;
    border: none; /* Remove default styling */
    border-top: 2px solid #822000; /* Change color and thickness */
    width: 100%; /* Set the width to 50% of the parent container */
}

.seperator {
    border: none; /* Remove default styling */
    border-top: 2px solid #822000; /* Change color and thickness */
    width: 100%; /* Set the width to 50% of the parent container */
    margin: 0px;

}

.creatureName {
    position: relative;
    margin-left: 0px;
    font-size: 25px;
    top: 5px;
    color: #822000;
}

.infoTitle {
    position: relative;
    margin-left: 0px;
    font-size: 20px;
    top: 5px;
    color: #822000
}

.titleColor {
    color: #822000
}

.infoDesc {
    color: black;
    word-wrap: break-word;
}

.triggerButton {
    all: unset;
    cursor: pointer;
}

.triggerButton:hover {
    text-decoration: underline;
}

.topInfo {
    position: relative;
    background-color: #e0dab8;
    border-radius: 8px;
    padding: 8px;
}

.behindImage {
    position: absolute; /* Position absolutely to fill the container */
    top: 50%; /* Center image vertically */
    left: 50%; /* Center image horizontally */
    transform: translate(-50%, -50%); /* Offset to center the image */

    width: 100%;
    height: 100%;
    object-fit: fill; /* Cover the container, stretching if necessary */
    object-position: center; /* Center the image */
    opacity: .3;
    border-radius: 8px;
}

.clearImage {
    position: relative; /* Position absolutely to fill the container */
    top: 50%; /* Center image vertically */
    left: 50%; /* Center image horizontally */
    transform: translate(-50%, -50%); /* Offset to center the image */
    width: 25vh;
    opacity: 1;
    border-radius: 10px;
}

.quote {
    border-bottom: 1px solid #323232;
    background-color: #b5b5b55d;
    display: flex; 
    flex-direction: column; 
    align-items: flex-end;
    margin: 5px;
}

.quoteList {
    margin: 1px;
    list-style: none;
    padding: 2px;
}

.quoteBy {
    position: relative;
    top: -10px;
    right: 50px;
}

.spellList {
    margin: 5px;
    padding-left: 6%;
}

.spellList2 {
    margin: 5px;
    padding-left: 3%;
    list-style-type: circle;
}

.spellList li {
    padding: 2px
}

.source {
    font-size: .8em;
    color: black;
}

.creatureType {
    position: relative;
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
}

.stickyStatGrid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */
    margin-bottom: 15px;
    color: #3c3c3c;
}

.stickyStatItem {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.stickyStatExtraWide {
    position: relative;
    width: 200%;
}

.extraInfo {
    font-style: italic;
    font-size: 12px;
}

.skillGridContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr)); /* Flexible columns */
    gap: .5%; /* Space between grid items */
    width: 100%;
}

.skillGridHeader {
    text-align: center;
    background-color: #f4f4f4;
    padding-bottom: 2%;
    width: 100%;
    font-size: .66vw; /* Responsive font size */
}

.skillGridItem {
    background-color: #e0e0e0;
    text-align: center;
    font-size: .8vw; /* Responsive font size */
    color: black;
}

.skillGridItem span {
    font-size: .5vw; /* Responsive font size */
}

.statBlockScroll {
    padding-top: 1%;
    overflow-y: scroll; 
}

.actionInfo {
    margin-top: 8px;
    margin-bottom: 8px;
}

.shadowBox {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); /* Bottom shadow */
}


  /* Style the scrollbar track */
::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
    height: 12px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
    background: #88888831; /* Color of the scrollbar */
}

.column:hover 
::-webkit-scrollbar-thumb {
    background: #888888cd; /* Color of the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovering over the scrollbar */
}

.dmViewButtonContainer {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    border-top: 1px solid #822000; /* Change color and thickness */
    border-bottom: 1px solid #822000; /* Change color and thickness */
}

.dummyButtons {
    display: flex;
    flex-direction: column;
    height: 3%;
    width: 100%;
    justify-content: flex-end;
}

.dmViewButtonContainer .dropdown {
    all: unset;
    position: relative;
    display: flex;
}


.encounterTitleButtonGroup {
    display: flex;
    width: 100%;
}

.dmStartButtons {
    display: flex;
    width: 100%;
    align-items: center
}

.turnButtons {
    display: flex;
    width: 100%;
    align-items: center
}

.turnText {
    display: flex;
    font-size: small;
    align-items: center
}

.dmViewButton {
    padding: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    text-wrap: nowrap;
}

.animatedMenu {
    animation: slideDown 0.25s ease-out forwards;
    /* Initial position for the slide up effect */
    transform: translateY(-10%);
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownButton {
    padding: 10px;
    border: none;
    cursor: pointer;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    color: rgb(0, 0, 0);
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    margin: 0;
    z-index: 2;
    width: fit-content;
}

/* Dropdown menu items */
.dropdown-item {
    display: flex;
    padding: 10px 2px;
    cursor: pointer;
    border-bottom: 1px dotted #ccc;
    justify-content: space-between;
    text-wrap: nowrap;

}

.dropdown-item:hover {
    background-color: #d9d9d9;
}

.streamingGif {
    position: relative;
    top: 15%;
    right: 2px;
    rotate: 45deg;
    height: 18px; 
    width: 18px; 
}

.magWrapper {
    border: 1px solid #ccc;
    border-left: 0;
    background-color: #f9f9f9;
    padding: 2px 2px 2px 2px;
}

.magOptions {
    margin: 0;
    border-radius: 10px 10px 0px 10px;
    width: 18px;
    height: 18px;
}

.encounterDropdownX {
    opacity: 1;
    position: relative;
    right: 0px;
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-weight: bolder;
}

.encounterDropdownX:hover {
    border: 1px dotted #ae2626;

}

.dropdown-item:hover .encounterDropdownX{
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.listItem {
    width: 100%;
    margin-top: 5px;
    background-color: #cfc9a8;
    box-sizing: border-box;
    border-radius: 5px;
}

.uploadImageTopInfo {
    display: flex;
    justify-content: center;
}

.encounterCreatureLeftContainer,
.searchListCreatureContainer,
.encounterCreatureContainer {
    height: 50px;
    display: flex;
    width: 100%;
}

.listItemOptions {
    box-shadow: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-gap: 1px; /* gap between items */
}

.effectsCounter {
    position: absolute;
    bottom: 0px;
    right: -5px;
    font-size: x-small;
    background-color: #b3b3b3c2;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    font-weight: bold;
}

.searchListCreatureContainer {
    align-items: center
}

.searchListCreatureDetails {
    display: flex;
    flex-direction: column;
    width: 90%
}

.searchCreatureSmallDetails {
    display: flex;
}

.searchListCreatureContainer:hover .searchCreatureX,
.searchListCreatureContainer:hover .monsterSearchAdd {
    opacity: 1;
}

.importInputs {
    display: flex;
    width: 96.6%;
    margin-top: 2%;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
    background-color: #e0d8b2;
    border-radius: 10px 10px 10px 10px;
    padding: 10px 0px 10px 10px;
}

.dndBImportContainer {
    display: flex;
    height: 30px;
}

.menuIconImports {
    height: inherit;
}

.dndBImportButtons {
    position: relative;
    display: inline-block;
    height: inherit;
}

.dndbInput {
    width: 80%;
    height: inherit;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 25px;
    border: 0;
    border-radius: 0 5px 5px 0;
    font-size: 16px
}

.dndbInputButtonSearch {
    position: absolute; 
    right: 6%;
    top: 15%;
    border: none; 
    background: none; 
    cursor: pointer;
    padding: 2px;
}

.dndbInputButtonSearch:hover {
    background-color: #84848472;
    border-radius: 5px;
}

.dndBInputContainer {
    display: flex;
}


.encounterMonsterName {
    width: 30%;
    font-size: .9em;
}

.listItemMiddleStats {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nameInputContainer {
    width: fit-content;
}

.nameInput {
    width: 100%;
    position: relative;
    font-family: 'Bitter', serif;
    font-weight: bold;
    font-size: .8em;
    background-color: #bcbcbc8c;
    color: #501400;
    border: none;
    margin: 0 0 4px 0;
    border-bottom: 2px dotted #919191;
    border-radius: 5px;
}

.titleInput {
    width: 90%;
}

.middleStatsInput {
    position: relative;
    min-width: 20px;
    max-width: 25px;
    text-align: center;
    margin: 0 2px 0 0;
    padding: none;
    font-family: 'Bitter', serif;
    font-weight: bold;
    font-size: 1em;
    background-color: #bcbcbc8c;
    color: inherit;
    border: none;
    border-bottom: 1px solid #919191;
    border-right: 1px solid #919191;
    border-top: 1px solid #919191;
    border-radius: 5px;
}

.statBlockSkillsInput {
    position: relative;
    min-width: 20px;
    max-width: 25px;
    text-align: center;
    margin: 0 2px 0 0;
    font-family: 'Bitter', serif;
    font-weight: bold;
    font-size: 1em;
    background-color: inherit;
    color: inherit;
    border: none;
}

.initiativeInputContainer {
    height: auto;
    position: relative;
    min-width: 22px;
    max-width: 30px;
}

.inputButton {
    margin: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    box-sizing: border-box;
    background-color: #f0f0f0;
    border: 1px solid #000000;
    border-radius: 3px;
}

.middleStatsLabel {
    font-size: 10px;
    background-color: #bcbcbc8c;
    border-bottom: solid 1px #919191;
    border-left: solid 1px #919191;
    border-top: solid 1px #919191;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.middleStatsContainer {
    position: relative;
    display: flex;
    width: 90%;
    margin: 0px;
    text-align: center;
    flex-direction: row;
    font-size: .8em;
    align-items: center

}

@keyframes editHpGrow {
    0% {
      transform: scale(.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;

    }
}


.editHpGrow {
    animation: editHpGrow 0.25s ease-out forwards;
}


.encounterCreaturesList {
    position: relative;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 5px;
    height: 100%;
    width: 100%;
}

.editStatsContainer {
    position: fixed;
    border-radius: 5px;
    border: 1px solid #000000;
    background-color: #e0d9b7;
    box-shadow: 1px 1px 10px #000000;
    width: 6%;
    z-index: 9999;
    padding: 0px 5px 5px 5px;
}

.editTeamContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    border-radius: 10px;
    border: 1px solid #000000;
    background-color: #e0d9b7;
    box-shadow: 1px 1px 10px #000000;
    width: fit-content;
    z-index: 9999;
    padding: 5px;
    align-items: center
}

.effectContainerFlag {
    position: absolute;
    transform: rotate(45deg);
    bottom: calc(0% - 6.5px);
    right:  calc(28%);
    background-color: inherit;
    height: 9px;
    width: 9px;
    border-bottom: inherit;
    border-right: inherit;
}

.hpContainerFlag {
    position: absolute;
    transform: rotate(45deg);
    bottom: 20%;
    left:  calc(100% - 8px);
    background-color: inherit;
    height: 16px;
    width: 16px;
    border-top: inherit;
    border-right: inherit;
}

.teamContainerFlag {
    position: absolute;
    transform: rotate(45deg);
    bottom: calc(0% - 6.5px);
    right:  calc(47.5%);
    background-color: inherit;
    height: 8px;
    width: 8px;
    border-right: inherit;
    border-bottom: inherit;
}

.hpChanges {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline
}

.teamChoices {
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 5px;
    justify-content: space-evenly;
}

.editHpContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 50%;
}

.extraHpContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;   
    height: 50%;
}

.extraHpInputs {
    height: 45%;
}

.hpTitle {
    font-size: .8vw;
}

.tempHp {
    color:#0fafb5
}

.editHpButton {
    height: 30%;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    font-family: 'Bitter', serif;
    color: white;
    border: 1px solid rgb(85, 85, 85);
    box-shadow: 1px 1px 5px black;
}

.editTeamColorButton {
    height: 100%;
    width: 20%;
    padding: 0px;
    font-family: 'Bitter', serif;
    color: white;
}

.editIsPlayerButton {
    padding: 0px;
    font-family: 'Bitter', serif;
    color: white;
    margin: 0;
    align-items: center;

}

.editIsPlayerButtonContainer {
    display: flex;
    font-size: 8px;
    background-color: #bcbcbc8c;
    border-bottom: solid 1px #919191;
    border: solid 1px #919191;
    border-radius: 2px;
    align-items: center;
    justify-content: space-between;
} 

.editIsPlayerText {
    color: black;
    padding-right: 5px;
}

.healButton {
    color: white;
    background-color: rgb(102, 174, 102);
}

.damageButton {
    color: white;
    background-color: rgb(174, 102, 102);
}

.editStatsInputExtra,
.editStatsInput {
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 30%;
    margin: 5% 0 5% 0;
    box-sizing: border-box;
    font-size: large;
    text-align: center;
}

.editStatsInputExtra {
    height: 50%;
    margin: 0;
}

#temphp:focus {
    outline-color: #0fafb5;
}

#override:focus {
    outline-color: #7c2204;
}

.monsterSearchIcon {
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.monsterEncounterIconContainer {
    box-sizing: border-box;
    position: relative;
    min-height: 50px;
    --v-width: 50px;
    min-width: var(--v-width); /* Set width */
    background-color: #00000000;
    margin: 0px 5px 0px 0px;
}

.monsterEncounterIconContainer:hover .uploadIconContainer,
.monsterEncounterIconContainer:hover .uploadIcon {
    opacity: 1;
}

.uploadIconContainer,
.monsterEncounterIcon {
    box-sizing: border-box;
    position: absolute;
    height: 100%;
    width: 100%;
}

.uploadIconContainer {
    background-color: #ffffff4d;
    opacity: 0;

}

.uploadIcon {
    opacity: 0;
    position: relative;
    left: 25%;
    top: calc(25%);
    width: calc(var(--v-width) - 50%); /* Subtracts 50% of the inherited value */
}

.encounterCreaturesHpContainer {
    position: relative;
    width: 100%;
    min-width: 60px;
    max-width: 65px;
    text-align: center;
}

.hpButtonPlayerOverlay {
    position: absolute;
    bottom: 1%;
    right: 0%;
    z-index: 100;
    height: 20px;
    width: 19px;

}
  
.encounterCreaturesHp {
    color: rgb(62, 172, 74);
    font-weight: bold;
    width: 100%;
    height: 100%;
}
 
.encounterCreatureX {
    display: flex;
    position: relative;
    opacity: 0;
    background: none;
    border: none;
    color: red;
    font-weight: bolder;
    cursor: pointer;
}

.searchCreatureX {
    opacity: 0;
    background: none;
    border: none;
    color: red;
    font-weight: bolder;
    cursor: pointer;
    padding-right: 30px;
}

.searchListButtonContainer{
    display: flex;
    justify-content: flex-end;
    width: 30%;
    height: 100%;
}

.side-menu {
    position: relative;
    top: 0;
    left: 0;
    width: 35px; /* Width when closed */
    height: 100%;
    background-color: #333;
    overflow-x: hidden;
    transition: width 0.3s ease; 
}

/* Width when the menu is open */
.side-menu.open {
    width: 255px;
}

/* Style for the toggle button */
.toggle-button {
    position: relative;
    background-color: #444;
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect for the toggle button */
.toggle-button:hover {
    background-color: #555;
}

/* Style for the menu content */
.menu {
    color: white;
    overflow: hidden;
    list-style: none;
    padding: 0px;
    white-space: nowrap;
    width: 100%;
    height: fit-content;
    
}

.loginImageFlex {
    display: flex;
    width: 90%;
}

.menuItem {
    color: white;
    cursor: pointer;
    width: 100%;
}

.menuIcon {
    width: 35px;
    height: 35px;
}

.menuItemLow {
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.menuHelpLink {
    color: #f1f1f1;
}

.clickable-image {
    width: 150px; /* Adjust the size as needed */
    height: auto;
    transition: opacity 0.3s ease;
}

.menuItem:hover {
    opacity: 0.8; /* Add a hover effect */
}

.flagpole-container {
    position: relative;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    width: 80%;
    height: 70%;
}

.listItemOptionsBottom {
    display: flex;
    width: 150%;
}
  
.flagpole {
    position: relative;
    left: 6%;
    top: 2px;
    width: 1px; 
    height: 15px;
    background-color: rgb(241, 236, 79);
    border: solid 2px rgb(92, 92, 92);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    perspective: 400px; /* Define a perspective to create depth */

}
  
.flag {
    position: relative;
    width: 9px; 
    height: 8px; 
    left: 1px;
    border: solid 1px rgb(92, 92, 92);
}
  
.flag-stripes {
    width: 100%;
    height: 100%;
}

.actionToken-container {
    display: flex;
    align-items: flex-end
}
  
/* Basic styling for each token */
.actionToken {
    width: 8px; /* Adjusted size */
    height: 8px; /* Adjusted size */
    margin: 5px;
    border-radius: 20%;
    border: 2px solid #3d0f00; 
    cursor: pointer;
    background: linear-gradient(to left, transparent 50%, rgb(144, 36, 0) 50%);
    background-size: 200% 100%;
    background-position: 0% 0;
    transition: background-position 0.4s ease; /* Smooth transition for animation */

}

/* State when token is filled (animation goes from left to right) */
.actionToken.filled {
    background-position: 0% 0; /* Completely filled from left to right */

}

/* State when token is unfilled (animation goes from right to left) */
.actionToken.unfilled {
    background-position: 100% 0; /* Completely unfilled from right to left */

}

/* Hover state for unfilled (animates fill from left to right) */
.actionToken.unfilled.hover {
    background-position: 30% 0; /* Animate the unfilled token to halfway filled */
    border-color: #822000; /* Animate the filled token to unfilled */
}

/* Hover state for filled (animates unfill from right to left) */
.actionToken.filled.hover {
    background-position: 70% 0; /* Animate the filled token to unfilled */
    border-color: #822000; /* Animate the filled token to unfilled */
}

@keyframes wave {
    0% {
        transform: translate3d(0, 0, 0) rotate(0deg);
    }
    50% {
        transform: translate3d(1px, 2px, 30px) rotate(10deg); /* Smaller wave */
    }
    100% {
        transform: translate3d(0, 0, 0) rotate(0deg);
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; 
    margin: 0;
}

.userGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    width: 95%;
    list-style-position: inside;
    padding: 0;
}

.userItem {
    background-color: #6b6b6bd0;
    padding: 5px;
    border-radius: 8px;
}


.react-tabs__tab {
    background: #faf6e076 !important;
    overflow: hidden;
    margin-right: 3px;
    bottom: -5px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: rgb(67, 67, 67) 0px 0px 2px 0px inset;
    border: none !important;
}

.react-tabs__tab-list {
    margin: 0 !important; 
}

.react-tabs__tab-panel {
    background: #f8f2d3d7 !important;
    border-left: 1px solid #822000;
    border-bottom: 1px solid #822000;
}

.react-tabs__tab--selected {
    background: #f9f1c7d7 !important;
    box-shadow: rgb(67, 67, 67) 0px 0px 2px 0px ;    
    bottom: -4px !important;
}

