
.mixerContainer {
    height: 100vh;
    background: rgb(116, 116, 116);
}

.containerFlex {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0% 5% 5% 5%;
    background-color: rgb(156, 156, 156);
}

.audioFlex {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid rgb(132, 132, 132);
    padding: 15px;
    
}

.volumeFlex {
    display: flex;
    align-items: center;
    width: 200px;
    gap: 15px;
}

.controls {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
}

.numDropdown {
    display: flex;
    flex-direction: column;
}

.controlsContainer {
    padding-top: 30px;
}