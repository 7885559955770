.page {
    display: flex;
    width: 100vw;
    height: 100vh;
    font-family: Arial, sans-serif;
    font-size: 25px;
    font-weight: bold;
}

.iconContainer {
    position: relative; 
    overflow: hidden; /* Hide the overflowing parts of the image */
    width: 225px;  /* This controls how wide the card is aka how much of the card is shown */
    height: 100%;  
    transition: width 0.5s ease-in-out; /* Smooth transition for container size */

}

.icon {
    position: absolute; /* Position the image inside the container */
    height: 100%; /* Set the image height */
    left: -180px; /* Adjust the position to show the desired portion */
    width: 600px; /* Set the image width */
    transition: left 0.5s ease-in-out; /* Smooth transition for container size */

}

.iconContainer:hover {
    width: 700px;
}

.iconContainer:hover .icon {
    left: -40px;
}

.detailsContainer {
    position: relative;
    opacity: 0;
    display: flex;
    height: 50px;
    top: 90%;
    width: 100%;
    justify-content: center;
    text-wrap: nowrap;
    color: white;
    text-shadow: -1px -1px 0 #000,  
               1px -1px 0 #000,
              -1px  1px 0 #000,
               1px  1px 0 #000; 
    transition: opacity .5s cubic-bezier(0.4, 0, 1, 1);
}

.detail {
    position: absolute;
}


.iconName {
    top: 0;
}
  
.iconDescription {
    bottom: 0;
    font-size: 16px;
}

.iconContainer:hover .detailsContainer {
    opacity: 1;
}